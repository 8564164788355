import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdMail } from "react-icons/md";
// import { MailIcon } from "./svg/Mail-Icon";
// import { GithubIcon } from "./svg/GithubIcon";
// import { LinkedinIcon } from "./svg/LinkedinIcon";
import { ExternalLink } from "./ExternalLink";

export const Footer = () => {
    return (
        <>
            <div className="flex flex-row flex-wrap mx-auto space-x-14 items-center">
                <a href="https://www.linkedin.com/in/stephenisau/" className="mx-3">
                    <FaLinkedin className="mr-3 text-3xl" />
                </a>
                <a href="https://github.com/stephenisau" className="mx-3">
                    <FaGithub className="mr-3 text-3xl" />
                </a>
                <a href="/contact" className="mx-3">
                    <MdMail className="mr-3 text-3xl" />
                </a>
            </div>
        </>
    )
}

