import { FC, forwardRef } from 'react';
import Head from "next/head";
import styles from "@/styles/Home.module.css";
import { data } from "../data";

import { Footer } from "@/components/Footer";
import { Navbar } from "@/components/Navbar";
import { Hero } from "@/components/Hero";
import { ProjectCard } from "@/components/ProjectCard";
import { ProjectProps } from "@/types/index";

export const Home: FC<{ projects: ProjectProps[] }> = ({ projects }) => {
  return (
    <>
      <div className="mx-auto w-screen">
        <Navbar />
      </div>
      <main className="container mx-auto">
        <Hero />
        <hr></hr>
        {/* Main section */}
        <section className="container mx-auto px-3 my-2" id="#projects">
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5">
            {projects && projects.map((project: ProjectProps, id: number | string) => (
              <ProjectCard
                title={project.title}
                description={project.description}
                subheader={project.subheader}
                mainImage={project.mainImage}
                slug={project.slug}
                tags={project.tags}
                key={id}
              />
            ))}
          </div>
        </section>
      </main>
      <span className="divide-y divide-opacity-25 divide-black"></span>
      {/* Footer section */}
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </>
  );
}

export default Home;

export async function getStaticProps(ctx: any) {
  return {
    props: { projects: data.projects }
  };
};
