import React, { FC } from "react";
import Image from "next/image";

import styles from "../styles/hero.module.scss";

export const Hero = () => {
  return (

    <div className="flex justify-between sm:flex-col md:flex-row justify-items-center items-center px-4 py-4">
      <img src="/me.jpg" alt="hero picture" className={styles.personal} />

      <div className="block px-3 py-3 mx-3">
        <h1 className="font-bold font-mono my-3 text-center p-3">
          Hi 👋, my name is Stephen and I'm a software developer living in San Francisco, welcome to my page!
        </h1>
        <p className="my-3 text-center font-mono p-3">
          I'm a full stack developer interested in web development, data analytics, and product. When I'm not programming or learning new things,
          I'm exercising, hanging out with friends, or finding interesting things to hack on. My non-programming interests include reading, music, and sports! (Go Lakers and the Warriors!) 
        </p>
      </div>
    </div>
  );
};
